//      

const StyleLayer = require('../style_layer');
const CircleBucket = require('../../data/bucket/circle_bucket');

                                                        

class CircleStyleLayer extends StyleLayer {
    createBucket(parameters                  ) {
        return new CircleBucket(parameters);
    }
}

module.exports = CircleStyleLayer;
