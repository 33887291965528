//      

const StyleLayer = require('../style_layer');
const LineBucket = require('../../data/bucket/line_bucket');

                                                        

class LineStyleLayer extends StyleLayer {
    createBucket(parameters                  ) {
        return new LineBucket(parameters);
    }
}

module.exports = LineStyleLayer;
